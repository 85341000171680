/* TODO need to change font-display https://helpx.adobe.com/fonts/using/font-display-settings.html */
@import url("https://use.typekit.net/xfd4zqn.css");


/*font-family: itc-avant-garde-gothic-pro, sans-serif;*/
/*font-weight: 300;*/
/*font-style: normal;*/
body {
    margin: 0;
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vis-network {
    outline: none !important;
}

.react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

a:link, a:visited {
    text-decoration: inherit;
    color: inherit;
}

a:link:hover, a:visited:hover {
    text-decoration: underline;
}
